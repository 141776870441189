@font-face {
  font-family: 'championhtf-welterweight';
  src: url("/champion-htf-welterweight-webfont.woff2") format("woff2"), url("/champion-htf-welterweight-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal
}

body {
  margin: 0;
  padding: 0;
  font-family: "Rubik", sans-serif;
  color: #233040;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: "Rubik", sans-serif;
  line-height: 1.2;
}

h1, h2 {
  font-family: 'championhtf-welterweight';
  font-weight: bold;
  text-transform: uppercase;
}

h3 {
  font-size: 1.5rem;
  font-weight: 500;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0.5rem;
}

h5 {
  font-weight: 400;
  font-size: 1.25rem;
  margin: 0 0 0.25rem;
}

p {
  line-height: 1.3;
}

input[type="text"], input[type="email"], input[type="submit"], textarea, select {
  border: 0;
  outline: none;
  background: white;
  font-size: 1rem;
  font-family: "Rubik", sans-serif;
  padding: 0.9rem 1.25rem;
  border-radius: 0;
  -webkit-appearance: none;
}

input:focus, textarea:focus {
}

button.link {
  background: none;
  padding: 0;
  outline: 0;
  -webkit-appearance: none;
  border: none;
  font-family: "Rubik", sans-serif;
  font-size: 1em;
  cursor: pointer;
}

button.link i {
  position: relative;
  top: 0.5rem;
}

button.primary, input.primary, a.primary {
  background: #2763E2;
  padding: 0.75rem 1.5rem;
  color: white;
  font-weight: bold;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
  text-decoration: none;
}

button.primary:hover, input.primary:hover {
  background: #1346B6;
}

button.full, input.full {
  width: 100%;
}

button.primary[disabled], input.primary[disabled] {
  opacity: 0.5;
}

button.danger {
  background: #EF4134;
}

.mobile-only {
  display: none;
}

@media (max-width: 30rem) {
  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none !important;
  }
}

#firebaseui-auth-container {
  position: fixed;
  z-index: 999;
  background: rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.site-navigation {
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 -1px 20px rgba(0, 0, 0, 0.07);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.site-navigation > ul {
  padding: 0;
  margin: 0;
}

.site-navigation > ul > li {
  display: block;
  float: left;
  width: 25%;
  position: relative;
}

.site-navigation > ul > li a {
  display: block;
  text-align: center;
  font-size: 1.125rem;
  padding: 0.5em;
  text-decoration: none;
  color: #233040;
}

.site-navigation a > img {
  width: 25px;
  margin: 0 auto 7px auto;
}

.site-navigation > ul > li span.menu-label {
  display: block;
  font-size: .5rem;
  text-transform: uppercase;
  white-space: nowrap;
}

.takeover {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fafafa;
  height: 100vh;
  width: 100%;
}

.takeover > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 250px;
}

.takeover > div > label {
  width: 100%;
}

.takeover > div > label > span {
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 6px;
}

.takeover > div > label > input {
  background: white;
  padding: 0.75rem;
  color: black;
  font-weight: bold;
  border: 1px solid gray;
  font-size: 1rem;
  text-decoration: none;
  width: 100%;
}

.takeover > div > button {
  margin-top: 1rem;
  width: 100%;
}
